@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html, body {
  -webkit-text-size-adjust: 100%; }

body {
  overflow-x: hidden; }

:focus-visible {
  outline: none; }

@layer utilities {
  .basic-show {
    opacity: 0;
    pointer-events: none; }
    .basic-show.is-show {
      opacity: 1;
      pointer-events: all; }
  .basic-hover {
    cursor: pointer;
    transition: all .3s; }
    .basic-hover:hover {
      opacity: .6; }
  .writing-vertical {
    writing-mode: vertical-rl; }
  .writing-unset {
    writing-mode: unset; }
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .img-contain {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .img-fill {
    width: 100%;
    height: 100%;
    object-fit: fill; }
  .img-scaledown {
    width: 100%;
    height: 100%;
    object-fit: scale-down; }
  .tf {
    top: 50%;
    left: 50%;
    --tw-translate-y: -50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); }
  .tf-x {
    left: 50%;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); }
  .tf-y {
    top: 50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); }
  .tf-x-auto {
    left: auto;
    transform: none; }
  .tf-y-auto {
    top: auto;
    transform: none; }
  .transform-none {
    transform: none; }
  .clip-0 {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); }
  .clip-x {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%); }
  .zoom-10 {
    zoom: 0.1; }
  .zoom-15 {
    zoom: 0.15; }
  .zoom-20 {
    zoom: 0.2; }
  .zoom-25 {
    zoom: 0.25; }
  .zoom-30 {
    zoom: 0.3; }
  .zoom-35 {
    zoom: 0.35; }
  .zoom-40 {
    zoom: 0.4; }
  .zoom-45 {
    zoom: 0.45; }
  .zoom-50 {
    zoom: 0.5; }
  .zoom-55 {
    zoom: 0.55; }
  .zoom-60 {
    zoom: 0.6; }
  .zoom-65 {
    zoom: 0.65; }
  .zoom-70 {
    zoom: 0.7; }
  .zoom-75 {
    zoom: 0.75; }
  .zoom-80 {
    zoom: 0.8; }
  .zoom-85 {
    zoom: 0.85; }
  .zoom-90 {
    zoom: 0.9; }
  .zoom-95 {
    zoom: 0.95; }
  .zoom-100 {
    zoom: 1; }
  .zoom-105 {
    zoom: 1.05; }
  .zoom-110 {
    zoom: 1.1; }
  .zoom-115 {
    zoom: 1.15; }
  .zoom-120 {
    zoom: 1.2; }
  .zoom-125 {
    zoom: 1.25; }
  .zoom-130 {
    zoom: 1.3; }
  .zoom-135 {
    zoom: 1.35; }
  .zoom-140 {
    zoom: 1.4; }
  .zoom-145 {
    zoom: 1.45; }
  .zoom-150 {
    zoom: 1.5; }
  .zoom-155 {
    zoom: 1.55; }
  .scroll-around {
    animation: rotate 18s infinite linear; }
  @keyframes rotate {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  .breathe {
    animation: breathe 5s linear infinite; }
  .breathe-3 {
    animation: breathe 10s linear infinite; }
  @keyframes breathe {
    0% {
      transform: scale(1); }
    50% {
      transform: scale(1.4); }
    100% {
      transform: scale(1); } }
  .breathe-2 {
    animation: breathe-2 7s linear infinite; }
  @keyframes breathe-2 {
    0% {
      transform: scale(0.9); }
    50% {
      transform: scale(1.4); }
    100% {
      transform: scale(0.9); } }
  .anim-play {
    animation-play-state: running; }
  .anim-pause {
    animation-play-state: paused; } }

body {
  font-family: 'Roboto', serif;
  overflow-x: hidden; }
  body.is-lock {
    margin-right: 17px;
    overflow: hidden; }

@screen lg {
  .br-mobile {
    border-radius: 160px 0 0 0; } }

#application-dots-index li {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border: 1px solid #fff; }

@screen lg {
  #application-dots-index li {
    width: 10px;
    height: 10px; } }
  #application-dots-index li.current {
    background-color: #fff; }

#sdkSliderList li.is-selected .stage {
  transform: translateX(0px) translateY(0px); }

@screen lg {
  #sdkSliderList li.is-prev .stage {
    transform: translateX(70px); } }

@screen lg {
  #sdkSliderList li.is-next .stage {
    transform: translateX(-44px); } }

#sdkSliderList li .stage {
  transform: translateX(0px) translateY(0px);
  transition: transform .5s .1s; }

#news-paging a {
  font-family: theme("fontFamily.en");
  position: relative;
  color: #5A6168;
  font-style: italic; }

@screen lg {
  #news-paging a {
    font-size: 14px; } }
  #news-paging a.tg {
    color: #86ddc6; }
    #news-paging a.tg.current {
      color: #fff; }
      #news-paging a.tg.current:before {
        background-color: #fff; }
  #news-paging a:before {
    content: "";
    position: absolute;
    width: 130%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #595757;
    opacity: 0; }
  #news-paging a.current {
    font-style: initial;
    font-weight: 700; }
    #news-paging a.current:before {
      opacity: 1; }

.certificationCats li .bg-liner {
  opacity: 0; }

.certificationCats li .text-1 {
  color: #fff; }

.certificationCats li .text-2 {
  color: #fff; }

.careersList li.is-open .ques {
  border-bottom: 2px solid #40BFB6; }
  .careersList li.is-open .ques svg {
    transform: rotate(180deg); }
    .careersList li.is-open .ques svg rect {
      fill: #2E2A95; }

.careersList li svg {
  transition: all .5s; }
  .careersList li svg rect {
    transition: all .5s; }

#in-dots li {
  cursor: pointer;
  width: 12px;
  height: 12px;
  background-color: #C8C8C8;
  transition: all .5s; }

@screen lg {
  #in-dots li {
    width: 10px;
    height: 10px; } }
  #in-dots li.current {
    background-color: theme("colors.green.DEFAULT"); }

#application-dots li {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all .5s; }
  #application-dots li.current {
    background-color: #fff; }

.pulsar, .pulsar-mobile {
  stroke-dasharray: 400;
  -webkit-animation: dash 2.5s infinite linear forwards; }

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: -800; }
  to {
    stroke-dashoffset: 800; } }

.yearList li {
  transition: all .3s; }
  .yearList li.current, .yearList li:hover {
    font-size: 22px;
    border-top: 1px solid #5A6168;
    color: #5A6168;
    padding-left: 20px; }

.pic-dots {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%); }
  .pic-dots li {
    width: 8px;
    height: 8px;
    background-color: #b3b3b3;
    margin: 0 5px; }
    .pic-dots li.current {
      background-color: #2e2a95; }

.yearList-mobile-white {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 5px;
  background: white; }

.yearList-mobile {
  padding: 0 1px; }
  .yearList-mobile li {
    position: relative; }
    .yearList-mobile li:after {
      position: absolute;
      content: "";
      width: 1px;
      height: 10px;
      background-color: #40bfb6;
      transition: all .5s;
      bottom: 0;
      left: 0; }
    .yearList-mobile li span {
      transition: all .5s;
      position: absolute;
      top: -44px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0; }
    .yearList-mobile li.current:after {
      transform: scaleY(2) translateY(-2px);
      background-color: #5a6168; }
    .yearList-mobile li.current span {
      opacity: 1; }

.time-area .item {
  overflow: hidden;
  width: 104px;
  height: 190px; }

@screen lg {
  .time-area .item {
    width: 34px;
    height: 56px; } }
  .time-area .item span {
    display: block; }

.st-item svg {
  transform: rotate(-90deg); }

#contactForm .item {
  position: relative; }
  #contactForm .item .title {
    display: inline-block;
    position: relative;
    @screen lg {} }
    #contactForm .item .title:after {
      position: absolute;
      content: "*";
      color: #c1272d;
      right: -14px;
      top: -1px;
      font-size: 18px; }
  #contactForm .item.message .title:after {
    opacity: 0; }

#contactForm .select {
  position: relative;
  width: 100%; }
  #contactForm .select:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(25deg);
    right: 24px;
    background-color: #40bfb6; }

@screen lg {
  #contactForm .select:before {
    right: 10px;
    height: 14px; } }
  #contactForm .select:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(-25deg);
    right: 32px;
    background-color: #40bfb6; }

@screen lg {
  #contactForm .select:after {
    right: 16px;
    height: 14px; } }

#contactForm input {
  @screen lg {} }

#contactForm select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  @screen lg {} }

#contactForm textarea {
  resize: none;
  background: #fff;
  border-bottom: 1px solid #fff;
  @screen lg {} }

#contactForm .contact-form-error {
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #c1272d; }

@screen lg {
  #contactForm .contact-form-error {
    font-size: 14px; } }

.fancy-scroll {
  /*定义滚动条高宽及背景
	高宽分别对应横竖滚动条的尺寸*/
  /*定义滚动条轨道
	内阴影+圆角*/
  /*定义滑块
	内阴影+圆角*/ }
  .fancy-scroll ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: rgba(102, 102, 102, 0.1); }
  .fancy-scroll ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(102, 102, 102, 0.3);
    background-color: rgba(102, 102, 102, 0.1); }
  .fancy-scroll ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(102, 102, 102, 0.3);
    background-color: #666666; }

#viewport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.contact-button {
  z-index: 1;
  position: relative; }
  .contact-button:hover:after {
    background: linear-gradient(to right, #72d8be 20%, #2d259b 100%) 0% 0/350% 100%;
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.7s;
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.7s, -webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .contact-button:after {
    content: '';
    z-index: -2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 200px;
    background: linear-gradient(to right, #72d8be 20%, #2d259b 100%) 0% 0/100% 100%;
    transition: background 0.8s, -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.8s;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1), background 0.8s, -webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1); }

.technologListWrap {
  position: relative;
  border: 1px solid #86ddc6;
  border-right: 0;
  border-radius: 50px 0px 0px 50px; }

@screen lg {
  .technologListWrap {
    border-radius: 24px 0px 0px 24px; } }
  .technologListWrap .line-1 {
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #86ddc6;
    width: 100%;
    height: 1px; }
    .technologListWrap .line-1:before {
      position: absolute;
      content: "";
      left: -6px;
      top: -6px;
      width: 12px;
      height: 12px;
      background-color: #2e2a95;
      z-index: 1; }
    .technologListWrap .line-1:after {
      position: absolute;
      content: "";
      left: calc(33.3333% - 6px);
      top: -6px;
      width: 12px;
      height: 12px;
      background-color: #2e2a95;
      z-index: 1; }
  .technologListWrap .line-2 {
    position: absolute;
    top: 66.6666%;
    left: 0;
    background-color: #86ddc6;
    width: 100%;
    height: 1px; }
    .technologListWrap .line-2:before {
      position: absolute;
      content: "";
      left: -6px;
      top: -6px;
      width: 12px;
      height: 12px;
      background-color: #2e2a95;
      z-index: 1; }
    .technologListWrap .line-2:after {
      position: absolute;
      content: "";
      left: calc(66.6666% - 6px);
      top: -6px;
      width: 12px;
      height: 12px;
      background-color: #2e2a95;
      z-index: 1; }
  .technologListWrap .line-3 {
    position: absolute;
    top: 0;
    left: 33.3333%;
    background-color: #86ddc6;
    width: 1px;
    height: 100%; }
  .technologListWrap .line-4 {
    position: absolute;
    top: 0;
    left: 66.6666%;
    background-color: #86ddc6;
    width: 1px;
    height: 100%; }
    .technologListWrap .line-4:after {
      position: absolute;
      content: "";
      left: -6px;
      top: calc(50% - 6px);
      width: 12px;
      height: 12px;
      background-color: #2e2a95;
      z-index: 1; }

.technologList li {
  position: relative;
  padding: 40px 60px;
  min-height: 300px; }

@screen lg {
  .technologList li {
    padding: 10px 20px;
    min-height: 150px; } }
  .technologList li.is-now {
    opacity: 1; }
    .technologList li.is-now .text {
      opacity: 1; }

@screen lg {
  .grList li.current .number, .grList li.current .text, .grList li.current .bg {
    opacity: 1; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #newsList {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    #newsList li {
      padding: 0 10px;
      margin-top: 0;
      margin-bottom: 40px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #sdkSliderList li {
    width: 154px;
    height: 320px;
    margin: 0 100px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #menuopening {
    height: 100%; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #flat-face {
    margin-top: 330px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #flat-text-1 {
    font-size: 20px;
    max-width: 80%;
    margin: 0 auto;
    margin-top: 200px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #flat-item-1 {
    max-width: 65%;
    margin: 50px auto; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #flat-item-2 {
    max-width: 65%;
    margin: 0 auto; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #rppg-head-2 {
    max-width: 65%;
    margin: 0 auto; } }

@media (min-width: 768px) and (max-width: 1200px) {
  .face-item {
    max-width: 300px; }
    .face-item .face-ch {
      font-size: 24px; }
    .face-item .face-content {
      font-size: 17px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #index-sdk {
    max-width: 400px;
    margin: 0 auto; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #index-logo li img {
    max-width: 280px;
    max-height: 150px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #sdk-data {
    max-width: 500px; } }

@media (min-width: 768px) and (max-width: 1200px) {
  .index-core {
    max-width: 70%;
    margin: 0 auto; } }

@media (min-width: 768px) and (max-width: 1200px) {
  .application-detail-head {
    padding-right: 10rem; } }

@media (min-width: 768px) and (max-width: 1200px) {
  .flat-br {
    display: none; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #specification {
    transform: scale(1.2);
    padding-top: 30px; } }

@media (min-width: 821px) and (max-width: 1200px) {
  .st-all {
    max-width: 62%;
    margin-top: 44px; } }

@media (min-width: 768px) and (max-width: 820px) {
  .st-all {
    max-width: 85%;
    margin-top: 44px; } }

#flat-mask {
  background: url("../images/t-head-mask-mobile.png") center center/cover; }
  @media (min-width: 821px) and (max-width: 1200px) {
    #flat-mask {
      background: url("../images/t-head-mask.svg") center center/cover;
      height: 120vh; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #rppgList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px 24px; }
    #rppgList li {
      margin-top: 0; } }

@media (min-width: 768px) and (max-width: 1200px) {
  #footerListWrap {
    display: flex;
    justify-content: center; } }

@media (min-width: 981px) and (max-width: 1200px) {
  #footerList {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 0 50px; } }

@media (min-width: 768px) and (max-width: 980px) {
  #footerList {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0 50px; } }

@media (min-width: 981px) and (max-width: 1200px) {
  #video-flat {
    display: block;
    max-width: 125%;
    height: 100vh; } }

@media (min-width: 981px) and (max-width: 1200px) {
  #video-mobile {
    display: none; } }

.faq-select:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(25deg);
  right: 24px;
  background-color: #40bfb6; }

@screen lg {
  .faq-select:before {
    right: 10px;
    height: 14px; } }

.faq-select:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(-25deg);
  right: 32px;
  background-color: #40bfb6; }

@screen lg {
  .faq-select:after {
    right: 16px;
    height: 14px; } }

.faq-select select {
  -webkit-appearance: none;
  /* for Safari and Chrome */
  -moz-appearance: none;
  /* for Firefox */
  appearance: none; }
