@tailwind base;
@tailwind components;
@tailwind utilities;

@import "basic";


:root{
	// --cmsLinkColor: theme('colors.brown.DEFAULT');
}

body{
	font-family: 'Roboto', serif;
	overflow-x: hidden;
	&.is-lock {
		margin-right: 17px;
		overflow: hidden;
	}
}
.br-mobile{
	@screen lg{
		border-radius: 160px 0 0 0;
	}
}

#application-dots-index{
	li{
		cursor: pointer;
		width: 12px;
		height: 12px;
		border: 1px solid #fff;
		@screen lg{
			width: 10px;
			height: 10px;
		}
		&.current{
			background-color: #fff;
		}
	}
}
#sdkSliderList{
	li{
		&.is-selected{
			.stage{
				transform: translateX(0px) translateY(0px);
			}
		}
		&.is-prev{
			.stage{
				// transform: translateX(-31px) translateY(70px);
				@screen lg{
					transform: translateX(70px);
				}
			}
		}
		&.is-next{
			.stage{
				// transform: translateX(46px) translateY(70px);
				@screen lg{
				    transform: translateX(-44px);
				}
			}
		}
		.stage{
			transform: translateX(0px) translateY(0px);
			// transform-origin: left;
			transition: transform .5s .1s;
		}
	}
}
#news-paging{
	a{
		font-family: theme('fontFamily.en');
		position: relative;
		color: #5A6168;
		font-style: italic;
		@screen lg{
			font-size: 14px;
		}
		&.tg{
			color: #86ddc6;
			&.current{
				color: #fff;
				&:before{
					background-color: #fff;
				}
			}
		}
		&:before{
			content: "";
			position: absolute;
			width: 130%;
			height: 1px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			background-color: #595757;
			opacity: 0;
		}
		&.current{
			font-style: initial;
			font-weight: 700;
			&:before{
				opacity: 1;
			}
		}
		&:hover{

		}
	}
}
.certificationCats{
	li{
		.bg-liner{
			opacity: 0;
		}
		.text-1{
			color: #fff;
		}
		.text-2{
			color: #fff;
		}
		// &:hover{
		// 	.bg-liner{
		// 		opacity: 1;
		// 	}
		// 	.text-1{
		// 		color: #2e2a95;
		// 	}
		// 	.text-2{
		// 		color: #2e2a95;
		// 	}
		// }
		// &.current{
		// 	.bg-liner{
		// 		opacity: 1;
		// 	}
		// 	.text-1{
		// 		color: #2e2a95;
		// 	}
		// 	.text-2{
		// 		color: #2e2a95;
		// 	}
		// }
		
	}
}
.careersList{
	li{
		&.is-open{
			.ques{
				border-bottom: 2px solid #40BFB6;
				svg{
					transform: rotate(180deg);
					rect{
						fill: #2E2A95;
					}
				}
			}
		}
		svg{
			transition: all .5s;
			rect{
				transition: all .5s;
			}
		}
	}
}
#in-dots{
	li{
		cursor: pointer;
		width: 12px;
		height: 12px;
		background-color: #C8C8C8;
		transition: all .5s;
		@screen lg{
			width: 10px;
			height: 10px;
		}
		&.current{
			background-color: theme('colors.green.DEFAULT');
		}
	}
}
#application-dots{
	li{
		cursor: pointer;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #fff;
		transition: all .5s;
		&.current{
			background-color: #fff;
		}
	}
}
.pulsar, .pulsar-mobile{
	stroke-dasharray: 400;
	-webkit-animation: dash 2.5s infinite linear forwards;
	@-webkit-keyframes dash{
		from{
			stroke-dashoffset: -800;
		}
		to {
			stroke-dashoffset: 800;
		}
	}
}
.yearList{
	li{

		transition: all .3s;
		&.current, &:hover{
			font-size: 22px;
			border-top: 1px solid #5A6168;
			color: #5A6168;
			padding-left: 20px;
		}
	}
}
.pic-dots{
	position: absolute;
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
	li{
		width: 8px;
		height: 8px;
		background-color: #b3b3b3;
		margin: 0 5px;
		&.current{
			background-color: #2e2a95;
		}
	}
}
.yearList-mobile-white{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 5px;
	background: white;
}
.yearList-mobile{
	padding: 0 1px;
	li{
		position: relative;
		&:after{
			position: absolute;
			content: "";
			width: 1px;
			height: 10px;
			background-color: #40bfb6;
			transition: all .5s;
			bottom: 0;
			left: 0;
		}
		span{
			transition: all .5s;
			position: absolute;
			top: -44px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
		}
		&.current{
			&:after{
				transform: scaleY(2) translateY(-2px);
				background-color: #5a6168;
			}
			span{
				opacity: 1;
			}
		}
	}
}
.time-area{
	.item{
		overflow: hidden;
		width: 104px;
    	height: 190px;
    	@screen lg{
    		width: 34px;
    		height: 56px;
		}
		span{
			display: block;
		}
	}
}
.st-item{
	svg{
		transform: rotate(-90deg);
	}
}
#contactForm{
	.item{
		position: relative;
		.title{
			display: inline-block;
			position: relative;
			@screen lg{
			}
			&:after{
				position: absolute;
				content: "*";
				color: #c1272d;
			    right: -14px;
			    top: -1px;
			    font-size: 18px;
			}
		}
		&.message{
			.title{
				&:after{
					opacity: 0;
				}
			}
		}
	}
	.select{
		position: relative;
		width: 100%;
		&:before{
			content: "";
		    position: absolute;
		    width: 2px;
		    height: 20px;
		    top: 50%;
		    transform: translateY(-50%) rotate(25deg);
		    right: 24px;
		    background-color: #40bfb6;
    		@screen lg{
    			right: 10px;
    			height: 14px;
    		}
		}
		&:after{
			content: "";
		    position: absolute;
		    width: 2px;
		    height: 20px;
		    top: 50%;
		    transform: translateY(-50%) rotate(-25deg);
		    right: 32px;
		    background-color: #40bfb6;
    		@screen lg{
    			right: 16px;
    			height: 14px;
    		}
		}
	}
	input{
		@screen lg{
		}
	}
	select{
		-moz-appearance:none; /* Firefox */
	    -webkit-appearance:none; /* Safari and Chrome */
	    appearance:none;
	    @screen lg{
		}
	}
	textarea{
		resize: none;
		background: #fff;
		border-bottom: 1px solid #fff;
		@screen lg{
		}
	}
	.submit{
		
	}
	.contact-form-error{
		pointer-events: none;
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
		color: #c1272d;
		@screen lg{
			font-size: 14px;
		}
	}
}


.fancy-scroll{
	/*定义滚动条高宽及背景
	高宽分别对应横竖滚动条的尺寸*/
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		background-color: rgba(#666666, .1);
	}
	/*定义滚动条轨道
	内阴影+圆角*/
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(#666666, 0.3);
		// border-radius: 10px;
		background-color: rgba(#666666, .1);
	}
	/*定义滑块
	内阴影+圆角*/
	::-webkit-scrollbar-thumb {
		// border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(#666666, .3);
		background-color: #666666;
	}
}

#viewport {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
.contact-button{
	z-index: 1;
	position: relative;
	&:hover{
		&:after{
	    	background: linear-gradient(to right, #72d8be 20%, #2d259b 100%) 0% 0/350% 100%;
		    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),background 0.7s;
		    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),background 0.7s,-webkit-transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}
	&:after{
		content: '';
	    z-index: -2;
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    border-radius: 200px;
	    background: linear-gradient(to right, #72d8be 20%, #2d259b 100%) 0% 0/100% 100%;
	    transition: background 0.8s,-webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
	    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1),background 0.8s;
	    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1),background 0.8s,-webkit-transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
	}
}
.technologListWrap{
	position: relative;
    border: 1px solid #86ddc6;
    border-right: 0;
    border-radius: 50px 0px 0px 50px;
    @screen lg{
	    border-radius: 24px 0px 0px 24px;
	}
	.line-1{
		position: absolute;
		top: 50%;
		left: 0;
		background-color: #86ddc6;
		width: 100%;
		height: 1px;
		&:before{
			position: absolute;
		    content: "";
		    left: -6px;
		    top: -6px;
		    width: 12px;
		    height: 12px;
		    background-color: #2e2a95;
		    z-index: 1;
		}
		&:after{
			position: absolute;
		    content: "";
		    left: calc(33.3333% - 6px);
		    top: -6px;
		    width: 12px;
		    height: 12px;
		    background-color: #2e2a95;
		    z-index: 1;
		}
	}
	.line-2{
		position: absolute;
		top: 66.6666%;
		left: 0;
		background-color: #86ddc6;
		width: 100%;
		height: 1px;
		&:before{
			position: absolute;
		    content: "";
		    left: -6px;
		    top: -6px;
		    width: 12px;
		    height: 12px;
		    background-color: #2e2a95;
		    z-index: 1;
		}
		&:after{
			position: absolute;
		    content: "";
		    left: calc(66.6666% - 6px);
		    top: -6px;
		    width: 12px;
		    height: 12px;
		    background-color: #2e2a95;
		    z-index: 1;
		}
	}
	.line-3{
		position: absolute;
		top: 0;
		left: 33.3333%;
		background-color: #86ddc6;
		width: 1px;
		height: 100%;
		// &:after{
		//     position: absolute;
		//     content: "";
		//     left: -6px;
		//     top: calc(66.6666% - 6px);
		//     width: 12px;
		//     height: 12px;
		//     background-color: #2e2a95;
		//     z-index: 1;
		// }
	}
	.line-4{
		position: absolute;
		top: 0;
		left: 66.6666%;
		background-color: #86ddc6;
		width: 1px;
		height: 100%;
		&:after{
		    position: absolute;
		    content: "";
		    left: -6px;
		    top: calc(50% - 6px);
		    width: 12px;
		    height: 12px;
		    background-color: #2e2a95;
		    z-index: 1;
		}
	}
}
.technologList{

	li{
		position: relative;
	    padding: 40px 60px;
	    min-height: 300px;
	    @screen lg{
    	    padding: 10px 20px;
		    min-height: 150px;
		}
	    &.is-now{
			opacity: 1;
			.text{
				opacity: 1;
			}
	    }
	}
}
.grList{
	li{
		&.current{
			.number, .text, .bg{
				@screen lg{
					opacity: 1;
				}
			}
		}
	}
}
#newsList{
	@media (min-width: 768px) and (max-width: 1200px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		li{
			padding: 0 10px;
			margin-top: 0;
			margin-bottom: 40px;
		}
	}
}
#sdkSliderList{
	li{
		@media (min-width: 768px) and (max-width: 1200px) {
			width: 154px;
			height: 320px;
			margin: 0 100px;
		}
	}
}
#menuopening{
	@media (min-width: 768px) and (max-width: 1200px) {
		height: 100%;
	}
}
#flat-face{
	@media (min-width: 768px) and (max-width: 1200px) {
		margin-top: 330px;
	}
}
#flat-text-1{
	@media (min-width: 768px) and (max-width: 1200px) {
		font-size: 20px;
	    max-width: 80%;
	    margin: 0 auto;
	    margin-top: 200px;
	}
}
#flat-item-1{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 65%;
	    margin: 50px auto;
	}
}
#flat-item-2{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 65%;
	    margin: 0 auto;
	}
}
#rppg-head-2{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 65%;
	    margin: 0 auto;
	}
}
.face-item{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 300px;
		.face-ch{
			font-size: 24px;
		}
		.face-content{
			font-size: 17px;
		}
	}
}
#index-sdk{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 400px;
	    margin: 0 auto;
	}
}
#index-logo{
	li{
		@media (min-width: 768px) and (max-width: 1200px) {
			img{
				max-width: 280px;
		    	max-height: 150px;
			}
		}
	}
}
#sdk-data{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 500px;
	}
}
.index-core{
	@media (min-width: 768px) and (max-width: 1200px) {
		max-width: 70%;
		margin: 0 auto;
	}
}.application-detail-head{
	@media (min-width: 768px) and (max-width: 1200px) {
		// max-width: 50%;
		padding-right: 10rem;
	}
}
.flat-br{
	@media (min-width: 768px) and (max-width: 1200px) {
		display: none;
	}
}
#specification{
	@media (min-width: 768px) and (max-width: 1200px) {
		transform: scale(1.2);
    	padding-top: 30px;
	}
}
.st-all{
	@media (min-width: 821px) and (max-width: 1200px) {
        max-width: 62%;
        margin-top: 44px;
	}
	@media (min-width: 768px) and (max-width: 820px) {
        max-width: 85%;
        margin-top: 44px;
	}
}
#flat-mask{
	background: url('../images/t-head-mask-mobile.png') center center / cover;
	@media (min-width: 821px) and (max-width: 1200px) {
	    background: url('../images/t-head-mask.svg') center center / cover;
	    height: 120vh;
	}
}
#rppgList{
	@media (min-width: 768px) and (max-width: 1200px) {
	    display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 24px 24px;
	    li{
    	    margin-top: 0;
	    }
	}
}
#footerListWrap{
	@media (min-width: 768px) and (max-width: 1200px) {
	    display: flex;
    	justify-content: center;
	}
}
#footerList{
	@media (min-width: 981px) and (max-width: 1200px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 0 50px;
	}
	@media (min-width: 768px) and (max-width: 980px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	    grid-gap: 0 50px;
	}
}
#video-flat{
	@media (min-width: 981px) and (max-width: 1200px) {
		display: block;
	    max-width: 125%;
    	height: 100vh;
	}
}
#video-mobile{
	@media (min-width: 981px) and (max-width: 1200px) {
		display: none;
	}
}
.faq-select{
	&:before{
		content: "";
	    position: absolute;
	    width: 2px;
	    height: 20px;
	    top: 50%;
	    transform: translateY(-50%) rotate(25deg);
	    right: 24px;
	    background-color: #40bfb6;
		@screen lg{
			right: 10px;
			height: 14px;
		}
	}
	&:after{
		content: "";
	    position: absolute;
	    width: 2px;
	    height: 20px;
	    top: 50%;
	    transform: translateY(-50%) rotate(-25deg);
	    right: 32px;
	    background-color: #40bfb6;
		@screen lg{
			right: 16px;
			height: 14px;
		}
	}
	select{
		-webkit-appearance: none;  /* for Safari and Chrome */
		-moz-appearance: none;     /* for Firefox */
		appearance: none;
	}
}